import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Flags from "../components/flagGrid";
import { Helmet } from "react-helmet";

export default function PageService({ data, location }) {
  const post = data.markdownRemark;
  return (
    <Layout className="card p-3" location={location}>
      <Helmet>
        <meta name="description" content={post.frontmatter.title} />
        <meta name="keywords" content={post.frontmatter.keywords} />
      </Helmet>
      <div className="card my-3">
        <div className="row p-3">
          <div className="p-3">
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
          </div>
        </div>
      </div>
      <Flags />
    </Layout>
  );
}

export const query = graphql`
  query PageServiceQuery($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        keywords
        slug
      }
    }
  }
`;
